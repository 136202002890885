<template>
  <div class="ReportsMenuContent">
    <LargeButton
      button
      primary
      dradius
      label="General"
      :desc="$locale['general_sales_resume']"
      icon="file-lines"
      @click="select(`/?feed=reports&report=general&view=statistics`)"
    />
    <LargeButton
      button
      primary
      dradius
      :label="$locale['users']"
      :desc="$locale['info_and_manage_users']"
      icon="users"
      @click="select(`/admin?report=user&admin=statistics-users&view=statistics&${currentQuery}`)"
    />
    <LargeButton
      button
      primary
      dradius
      :label="$locale['subscriptions_cancelled']"
      :desc="$locale['subscriptions_cancelled_desc']"
      icon="file-lines"
      @click="select(`/?feed=reports&report=subscriptions_cancelled&view=statistics`)"
    />
    <LargeButton
      button
      primary
      dradius
      :label="$locale['payments']"
      :desc="$locale['payments_desc']"
      icon="file-lines"
      @click="select(`/?feed=reports&report=payments&view=statistics`)"
    />
  </div>
</template>

<script>
export default {
  props: ["modal"],
  methods: {
    select: function(data) {
      this.modal.close(() => {
        this.router(data);
      });
    },
  },
  computed: {
    currentQuery: function() {
      let query = "";

      if (this.$route.query.period) {
        query += `period=${this.$route.query.period}`;
      }

      if (this.$route.query.startDate) {
        query += `&startDate=${this.$route.query.startDate}`;
      }

      if (this.$route.query.endDate) {
        query += `&endDate=${this.$route.query.endDate}`;
      }

      return query;
    },
  },
};
</script>
